<template>
  <b-modal id="cn-login-modal">
    <img style="width: 431px;height: 615px" src="@/assets/image/cn/login_paw.png" alt="">
    <div class="login-body">
      <div class="login-in">
        <!--        Sign in-->
        <div class="login-first" style="text-align: center">{{ $t('message.login') }}</div>
        <div v-if="isError" class="alert alert-danger" role="alert">{{ errormsg }}</div>
        <!--        User name-->
        <div class="login-two">
          <div>用户名</div>
          <input v-model="loginForm.UserName" :placeholder="$t('message.username')" type="text" class="form-control">
        </div>
        <!--        Password-->
        <div class="login-three">
          <div>
            <div>密码</div>
            <div class="hand" @click="handleForgetPwd">忘记密码?</div>
          </div>
          <input v-model="loginForm.Password" :placeholder="$t('message.password')" type="password" class="form-control">
        </div>
        <!--        Remember-->
        <div class="login-four" style="width: 75px">
          <input v-model="remember" type="checkbox">
          <span>{{ $t('message.remeberme') }}</span>
        </div>
        <div class="login-five">
          <div class="hand" @click.prevent="handleLogin">{{ $t('message.login') }}</div>
          <div @click="handleBtcEyes" class="link hand">取消并返回</div>
        </div>
        <!--       Footer-->
        <div class="login-six">
          <div>
            <span>不是会员?</span>
            <span @click="handleRegister" class="link hand">立即注册</span>
          </div>
          <div style="margin-left: 10px">
            <span @click="handleContactUs" class="link hand">联系我们</span>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getLocalStore, setLocalStore } from '@/utils/common'
import { mapGetters } from 'vuex'

export default {
  inject: ['reload'],
  props: {

  },
  data () {
    return {
      errormsg: '',
      loginForm: {
        UserName: '',
        Password: ''
      },
      logined: false,
      isError: false,
      remember: false,
      captcha: null,
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'token',
      'userow'
    ])
  },
  updated () {
    // 回车登录
    document.onkeyup = (e) => {
      if (e.keyCode === 13 && (!this.logined && (!getLocalStore().username && !getLocalStore().password))) {
        this.handleLogin()
      }
    }
  },
  methods: {
    handleLogin () {
      this.$store.dispatch('Login', this.loginForm).then(() => {
        this.$store.dispatch('GetUserProfile', this.loginForm.UserName).then(() => {
          this.logined = true
          this.isError = false
          // 登录成功保存Token
          if (this.remember) {
            setLocalStore({ token: this.token })
          }
          this.getUserProfile()
          this.$bvModal.hide('cn-login-modal')
        })
        this.$store.dispatch('UpdateUserActivityByToken', this.token)
      }).catch((err) => {
        this.isError = true
        if (err.ErrorCode === 6009) {
          this.errormsg = this.$t('message.userpwdnotmatch')
        }
      })
    },
    getUserProfile () {
      this.$store.dispatch('GetUserProfile', getLocalStore().UserID).then(() => {
        this.reload()
      })
    },
    // 忘记密码
    handleForgetPwd () {
      this.$bvModal.hide('cn-login-modal')
      this.$bvModal.show('cn-forgot-password-modal')
    },
    handleBtcEyes () {
      this.$bvModal.hide('cn-login-modal')
      this.$router.push('/cn/BTCInfo')
    },
    handleRegister () {
      this.$bvModal.hide('cn-login-modal')
      this.$router.push('/cn/Register')
    },
    handleContactUs () {
      this.$bvModal.hide('cn-login-modal')
      this.$router.push('/cn/ContactUs')
    }
  }
}
</script>

<style>
#cn-login-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #535353;
  max-width: 1013px;
  height: 90vh;
}

#cn-login-modal .modal-content{
  border-radius: 20px;
}

#cn-login-modal .modal-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

#cn-login-modal .modal-footer {
  display: none;
}

#cn-login-modal .modal-header {
  display: none;
}
</style>

<style scoped>
/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
.link{
  height: 20px;
  font-size: 10px;
  color: #4285F4;
}

.link:hover{
  border-bottom: 1px solid #000000;
}

</style>
