import marked from 'marked'
import { setChargeStore, getChargeStore, delChargeStore } from './common'

export function setFirstWarn (userid, expireDate) {
  if (expireDate) {
    const day = (new Date(expireDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
    if (day >= 23 && day <= 30) {
      setChargeStore(userid, { firstWarn: false })
    } else if (day >= 15 && day < 23) {
      setChargeStore(userid, { secondWarn: false })
    } else if (day >= 8 && day < 15) {
      setChargeStore(userid, { thirdWarn: false })
    } else if (day >= 0 && day < 8) {
      setChargeStore(userid, { fourthWarn: false })
    } else {
      delChargeStore(userid)
    }
  }
}

export function getFirstWarn (userid, expireDate) {
  let isWarn = false
  if (expireDate) {
    const day = (new Date(expireDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
    if (getChargeStore(userid)) {
      if (day >= 23 && day <= 30) {
        isWarn = isEmpty(getChargeStore(userid).firstWarn)
      } else if (day >= 15 && day < 23) {
        isWarn = isEmpty(getChargeStore(userid).secondWarn)
      } else if (day >= 8 && day < 15) {
        isWarn = isEmpty(getChargeStore(userid).thirdWarn)
      } else if (day >= 0 && day < 8) {
        isWarn = isEmpty(getChargeStore(userid).fourthWarn)
      } else {
        isWarn = false
        delChargeStore(userid)
      }
    } else {
      isWarn = false
    }
  } else {
    isWarn = false
  }
  return isWarn
}

function isEmpty (data) {
  return data === '' || data === undefined || data === null
}

export function genRandomStr (length) {
  const strArr = []
  const baseCharArr = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
    'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B',
    'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4',
    '5', '6', '7', '8', '9', '0'
  ]
  for (let i = 0; i < length; i++) {
    strArr.push(baseCharArr[Math.floor(Math.random() * baseCharArr.length)])
  }
  return strArr.join('')
}

export function isWeChat () {
  return window.navigator.userAgent.toLowerCase().includes('micromessenger')
}

// 获取url参数
export function getQueryVariable (variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

// 格式化日期
export function dateFormat (fmt, date) {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

export function markdownToHTML (data) {
  let html = marked(data)
  /** 全局样式 **/
  // 去掉空样式
  html = html.replace(/style=""/g, '')
  // 去掉链接中的空格
  const imgArr = html.match(/http:.*?(\.jpg|\.png)/g)
  if (imgArr) {
    const tempArr = imgArr.map((imgSrc) => { return imgSrc.replace(/\s/g, '%20') })
    imgArr.map((val, index) => {
      html = html.replace(imgArr[index], tempArr[index])
    })
  }
  // 修改新网站数据段落样式
  html = html.replace(/<h4 /g, '<h4 style="font-size:55px;font-weight:300;text-align: justify;line-height: 80px" ')
  html = html.replace(/<h6 /g, '<h6 style="font-size:55px;line-height: 80px" ')
  // 段尾换行
  // <br>
  html = html.replace(/。<\/h4>/g, '。</h4><p style="padding: 5px"></p>')
  // 调整图片宽度
  html = html.replace(/<img /g, '<img style="width: 100%;" ')
  // 去掉段尾声明
  html = html.replace(/【来源：BTC必孚（中国）】/, '')
  // 去掉####
  html = html.replace(/<p>####<\/p>/g, '')

  // 去掉 strong
  html = html.replace(/<strong>/g, '')
  html = html.replace(/<\/strong>/g, '')
  html = html.replace(/<span.*?>/g, '')
  html = html.replace(/<\/span>/g, '')

  html = html.replace(/style="width: ([0-9]+)px;"/g, '')

  // 加粗
  html = html.replace(/<em/g, '<em style="font-weight: bold"')

  /** 局部样式 **/
  // 去掉无效字符（准入国牛肉出口数据，2019-08-27）
  html = html.replace(/<="" div=""/g, '')
  return html
}
