import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import data from './modules/data'
import price from './modules/price'
import etc from './modules/etc'
import eyes from './modules/eyes'
import market from './modules/market'
import news from './modules/news'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    data,
    price,
    etc,
    eyes,
    market,
    news
  },
  getters
})
