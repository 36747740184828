<template>
  <div
    style="display: flex;flex-direction:column;justify-content: center;align-items: center;  background-color: rgb(230,230,230);">
    <div class="pre-footer">
      <b-row>
        <b-col cols="4" style="text-align:left">
          <div>
            <h2>BTC Service</h2>
          </div>
          <div class="footer-service" style="font-size:14px;color: #535353; cursor: pointer;">
            <li v-for="(item, index) in tabs" :key="index" tabindex="1" @click="handleClickTab(item, index)">
              <a>{{ item.text }}</a>
            </li>
          </div>
        </b-col>
        <b-col cols="4" style="text-align:left;padding-left: 80px">
          <div @click="read(url)">
            <div class="hand2">BTC Membership</div>
          </div>
          <div @click="read(url)" class="footer-service">
            <div class="hand2-txt">Join BTC</div>
          </div>
          <div>
            <div class="hand3">Legal & Privacy</div>
          </div>
          <div class="footer-service">
            <div class="hand" @click="handleTerms">Terms & Conditions</div>
            <div class="hand" @click="handlePrivacyPolicy">Privacy Policy</div>
          </div>
          <div class="follow-us">
            <div class="hand3">Follow Us</div>
            <div>
              <img class="hand" src="@/assets/image/footer/linkedIn.png" alt=""
                @click="handleFollowUs('https://www.linkedin.com/company/beeftochina.ca/posts/?feedView=all')">
                <!--
                <img class="hand" src="@/assets/image/footer/face-book.png" alt="" @click="handleFollowUs('https://www.facebook.com/Beef-to-China-111080871164044')">
                <img class="hand" src="@/assets/image/footer/tweet.png" alt="" @click="handleFollowUs('https://twitter.com/china_beef')">
                <img class="hand" src="@/assets/image/footer/youtube.png" alt="" @click="handleFollowUs('https://www.youtube.com/channel/UCvZGluEJEfdIgahbnCHKK-g')">
                -->
            </div>
          </div>
        </b-col>
        <b-col cols="4"
          style="text-align:right;display: flex;flex-direction: column;justify-content: space-between;padding: 0">
          <!--            <h2 class="hand" @click="read(url)">BTC Membership
              <div style="font-size: 14px;margin-top: 18px;font-weight: 500">Join BTC</div>
            </h2> -->
          <div style="text-align: right;" class="hand">
            <h2 style="margin-top: 30px;cursor: default;">App Download</h2>
          </div>
          <div class="contact-us">
            <div class="contact-us-img">
              <div class="us-img">
                <img @click="read('https://apps.apple.com/us/app/beef-to-china/id6444113737?l=zh')"
                  style="width: 93px;height: 28px;margin-top: 10px;cursor: pointer;" src="@/assets/image/en/Iphone.png"
                  alt="">
                <img @click="read('https://play.google.com/store/apps/details?id=en.com.beeftochina')"
                  style="width: 93px;height: 28px;margin-top: 10px;cursor: pointer;"
                  src="@/assets/image/en/GoogleDocs.png" alt="">
              </div>
              <div>
                <img style="width: 87px;height: 85px;margin-top: 10px" src="@/assets/image/en/qcode.png" alt="">
                <!-- <div style="font-size: 14px;color: #535353">扫描下载</div> -->
              </div>
            </div>
          </div>
          <div class="contact-us">
            <h2 class="hand" style="margin-top: 19px;" @click="handleContactUs">Contact Us</h2>
            <div>
              <span>Contact Us</span>
              <a href="mailto:contact@beeftochina.com">contact@beeftochina.com</a>
            </div>
            <div>
              <span>Membership Service</span>
              <a href="mailto:membership@beeftochina.com">membership@beeftochina.com</a>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="footer">
      <b-row>
        <b-col class="col-md-12 col-sm-12 padding-top-10" style="text-align: center">
          Copyright © 2014-2024 Beef to China. All Rights Reserved.<br>
          <a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备19014942号-1</a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      tabs: [
        { text: 'Homepage', href: '/Homepage' },
        { text: 'BTC Eyes', href: '/BTCEyes' },
        { text: 'BTC Info', href: '/BTCInfo' },
        { text: 'BTC Data', href: '/BTCData' },
        { text: 'BTC Price', href: '/BTCPrice' },
        { text: 'BTC Market', href: '/Market' },
        { text: 'BTC Est.', href: '/BTCEst' }
      ],
      url: `${process.env.VUE_APP_STATIC}CnPlantIcon/pdf/BTCMembership.pdf`
    }
  },
  methods: {
    handleClickTab (item, index) {
      this.$router.push(item.href)
      this.current = index
      sessionStorage.setItem('navindex', index)
    },
    handleFollowUs (url) {
      window.open(url)
    },
    handleContactUs () {
      this.$router.push('/ContactUs')
    },
    handleTerms () {
      this.$router.push('/Terms')
    },
    handlePrivacyPolicy () {
      this.$router.push('/PrivacyPolicy')
    },
    read (url) {
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
li{
  list-style: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.pre-footer {
  height: 321px;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image:url(../../../../assets/image/footer/background-image.png);
  color: #b0b0b0;
  width: 960px;
}
.pre-footer .container {
  padding-top: 45px;
}
.pre-footer a {
  color: rgb(83, 83, 83);
}
.pre-footer h2,
.ecommerce .pre-footer h2 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 21px;
  color: #535353;
}
.pre-footer p {
  margin-bottom: 20px;
}
.pre-footer li {
  margin-top: 8px;
}
.footer-service {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px!important;
}
.footer-service div{
  margin-top: 10px;
  color: #535353;
}
.follow-us {
  margin-top: 18px;
}
.follow-us > div{
  width: 200px;
  display: flex;
  justify-content: space-between;
  // margin-top: 18px;
}

.follow-us > div > img{
  width: 27px;
  height: 27px;
  margin-top: 10px;
}

.contact-us {
  font-weight: 500;
  text-align: right;
  font-size: 12px!important;
  margin-bottom: 13px;
}

.contact-us > div{
  margin-top: 5px;
}

.contact-us > div span:first-child {
  color: #808080;
}

.contact-us > div > a {
  margin-left: 5px;
  color: #535353;
}

/* footer */
.footer {
  width: 100%;
  background: #272626;
  color: #fff;
  font-size: 12px;
  padding: 15px 0;
}
.footer-light {
  background: #F9F9F9;
  color: #3e4d5c;
}
.footer-gray {
  background: #4A5866;
}
.footer a {
  color: #fff;
  text-decoration: underline;
}
.footer a:hover {
  text-decoration: none;
}
.footer-light a {
  color: inherit;
}
.footer .padding-top-10 {
  opacity: 0.5;
}
.footer .list-inline > li:last-child {
  padding-right: 0;
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer ul.social-footer {
  font-size: 18px;
  padding-top: 5px;
}
.footer ul.social-footer a {
  opacity: 0.5;
}
.footer ul.social-footer a:hover {
  opacity: 1;
}
.hand2{
  margin-top: 30px;
  height: 29px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  line-height: 29px;
  color: #535353;
  opacity: 1;
  cursor: pointer;
}
.hand2-txt{
  margin-top: 0;
  cursor: pointer;
}
.hand3{
  margin-top: 20px;
  height: 29px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  line-height: 29px;
  color: #535353;
  opacity: 1;
}
.contact-us-img{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
.us-img{
  display: flex;
  flex-direction: column;
  margin-right: 11px;
}
</style>
