// import Cookies from 'js-cookie'

import { getLocalToken, setLocalToken, removeLocalToken } from './common.js'

// const TokenKey = 'btcweb'

export function getToken () {
  return getLocalToken()
  // return Cookies.get(TokenKey)
}

export function setToken (token) {
  return setLocalToken(token)
  // console.log('----')
  // return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return removeLocalToken()
  // console.log('remove----')
  // return Cookies.remove(TokenKey)
}

export function btcguid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}
