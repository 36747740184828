export default {
  // login、signup
  userTemp: state => state.user.temp,
  token: state => state.user.token,
  duringlogout: state => state.user.duringlogout,
  name: state => state.user.name,
  userow: state => state.user.row,
  userAvator: state => state.user.userAvator,
  userRedDot: state => state.user.redDot,
  type: state => state.user.type,
  isExpire: state => state.user.isExpire,
  isEnExpire: state => state.user.isEnExpire,
  isClear: state => state.user.isClear,
  isClose: state => state.user.isClose,
  userRow: state => state.user.userRow,
  userInfo: state => state.user.userInfo,
  userProfile: state => state.user.profile,
  // data
  dataChart: state => state.data.chart,
  dataTable: state => state.data.table,
  dataOption: state => state.data.option,
  // price
  priceRow: state => state.price.row,
  priceRows: state => state.price.rows,
  priceTotal: state => state.price.total,
  priceCountry: state => state.price.country,
  priceCuts: state => state.price.cuts,
  priceDate: state => state.price.date,
  // etc
  etcRow: state => state.etc.row,
  etcRows: state => state.etc.rows,
  etcPlantOrigins: state => state.etc.approvePlantOrigin,
  etcPlantPlantNos: state => state.etc.approvePlantPlantNo,
  etcPlantProvinces: state => state.etc.approvePlantProvince,
  etcPlantDatas: state => state.etc.approvePlantData,
  etcPlantPatterns: state => state.etc.approvePlantPattern,
  // eyes
  eyesRow: state => state.eyes.row,
  eyesRows: state => state.eyes.rows,
  eyesTotal: state => state.eyes.total,
  isLoading: state => state.eyes.isLoading,
  // market
  marketRow: state => state.market.row,
  marketRows: state => state.market.rows,
  marketTotal: state => state.market.total,
  marketDict: state => state.market.dict,
  marketItem: state => state.market.item,
  // news
  newsid: state => state.news.newsid,
  newsRow: state => state.news.row,
  newsRows: state => state.news.rows,
  newsTotal: state => state.news.total
}
