import api from '@/api/data'

export default {
  state: {
    chart: {},
    table: {},
    option: {}
  },

  mutations: {
    SET_CHART: (state, chart) => {
      state.chart = chart
    },
    SET_TABLE: (state, table) => {
      state.table = table
    },
    SET_OPTION: (state, option) => {
      state.option = option
    }
  },

  actions: {
    GetBeefWebDataOneChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataOneChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataOneTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataOneTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataTwoChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataTwoChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataTwoTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataTwoTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataThreeChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataThreeChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataThreeTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataThreeTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFourChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFourChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFourTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFourTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFiveChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFiveChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFiveTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFiveTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSixChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSixChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSixTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSixTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSevenChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSevenChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSevenTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSevenTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataEightChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataEightChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataEightTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataEightTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataNineChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataNineChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataNineTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataNineTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetOptionData ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetOptionData(params).then(response => {
          commit('SET_OPTION', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataOneChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataOneChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataOneTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataOneTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataTwoChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataTwoChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataTwoTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataTwoTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataThreeContrastChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataThreeContrastChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataThreeContrastTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataThreeContrastTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataFiveChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataFiveChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataFiveTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataFiveTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataSixChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataSixChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataSixTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataSixTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataThreeChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataThreeChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataThreeTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataThreeTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataFourContrastChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataFourContrastChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataFourContrastTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataFourContrastTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataFourChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataFourChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkNewDataFourTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkNewDataFourTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefCompanyTrend ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefCompanyTrend(params).then(response => {
          commit('SET_TABLE', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefUserTrend2 ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefUserTrend2(params).then((response) => {
          commit('SET_TABLE', response.Data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
