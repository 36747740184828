import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/global.css'
import '@/styles/dialog.css'
import '@/styles/fonts.css'
import dayjs from 'dayjs'
import Router from 'vue-router'
import VueI18n from 'vue-i18n'
import { cn } from '@/assets/lang/zh-cn'
import { en } from '@/assets/lang/en'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.prototype.dayjs = dayjs

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
Vue.use(Router)
Vue.use(ElementUI)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push

Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// vue跳转新页面后回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

const i18n = new VueI18n({
  locale: sessionStorage.lang || 'zh-cn',
  messages: {
    en: en,
    'zh-cn': cn
  },
  silentTranslationWarn: true
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
