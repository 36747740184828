<template>
  <b-modal :id="id" modal-class="common-modal" @hidden="handleModal">
    <div style="color: #3F4C5C; text-align: center;" v-html="msg"/>
  </b-modal>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      noSkip: ['companyInfoDialog', 'changePwdDialog', 'sentMembershipsDialog', 'datedialog', 'sendEmailDialog']
    }
  },
  computed: {
    msg () {
      if (this.message) {
        return this.$t(`message["${this.message}"]`)
      } else {
        return ''
      }
    }
  },
  methods: {
    // 当模态框隐藏时
    handleModal () {
      if (!this.noSkip.includes(this.id)) {
        this.$router.push('/BTCInfo')
      }
    }
  }
}
</script>

<style>
.common-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  height: 90vh;
}

.common-modal .modal-content {
  background-color: #fff0;
  border: none;
}

.common-modal .modal-header {
  background-image:url(../../assets/image/login/dialog-header.png);
  border: none;
  margin-bottom: -5px;
  z-index: 999;
}

.common-modal .modal-body {
  max-height: 300px;
  background-color: #ffffff;
  border-radius: 20px;
}

.common-modal .modal-footer {
  display: none;
}
</style>

<style scoped>

</style>
