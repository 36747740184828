<template>
  <div>
    <b-modal id="forgot-password-modal">
      <img src="@/assets/image/login/login-dialog.png" alt="">
      <div class="login-body">
        <div class="login-in">
          <!--Sign in-->
          <div class="login-first" style="text-align: center">{{ $t('message.forgetpwd') }}</div>
          <div v-if="errmsg!==''" class="alert alert-danger" role="alert">{{ errmsg }}</div>
          <!--User name-->
          <div class="name-email">
            <div>Username</div>
            <div>
              <input v-model="emailBody.Name" name="UserName" :placeholder="$t('message.username')" type="text"
                     class="form-control" :class="{ error : nameerror }">
              <span class="error-info"><span>{{ nameerror }}</span></span>
            </div>
          </div>
          <!--Email-->
          <div class="name-email">
            <div>Email</div>
            <div>
              <input v-model="emailBody.Email" name="Email" :placeholder="$t('message.email')" type="text"
                     class="form-control" :class="{ error : emailerror }">
              <span class="error-info"><span>{{ emailerror }}</span></span>
            </div>
          </div>
          <!--Button-->
          <div class="login-five">
            <div class="hand" @click="resetPasswd">Reset Password</div>
            <div>
              <span class="link" @click="handleInfo">Cancel and return to website</span>
            </div>
          </div>
          <!--Footer-->
          <div class="login-six">
            <div>
              <span>Not a member ?</span>
              <span class="link" @click="handleHide">Register now</span>
            </div>
            <div style="margin-left: 10px">
              <span>Terms Privacy Security ?</span>
              <span class="link" @click="handleContactUs">Get In Touch</span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <common-dialog id="mailDialog" :message="mailmsg"/>
  </div>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'

export default {
  name: 'ForgotPassword',
  components: { CommonDialog },
  data () {
    return {
      emailBody: {
        Name: '',
        Email: '',
        isEn: false
      },
      nameerror: '',
      emailerror: '',
      canSend: false,
      errmsg: '',
      mailmsg: ''
    }
  },
  mounted () {},
  methods: {
    resetPasswd () {
      this.checkForm()
      if (this.canSend) {
        this.$store.dispatch('SetLoadingStatus', true)
        this.emailBody.isEn = this.$i18n.locale === 'en'
        this.$store.dispatch('ForgetWebPassword', this.emailBody).then(() => {
          this.errmsg = ''
          this.mailmsg = 'mailmsg'

          this.handleHide()
          this.$bvModal.show('mailDialog')
          this.$store.dispatch('SetLoadingStatus', false)
        }).catch((err) => {
          if (err.ErrorCode === 6009) {
            this.errmsg = this.$t('message.useremailnotmatch')
          }
          if (err.ErrorCode === 6010) {
            this.errmsg = this.$t('message.nouser')
          }
          this.$store.dispatch('SetLoadingStatus', false)
        })
      }
    },
    checkForm () {
      if (!this.emailBody.Name) {
        this.nameerror = this.$t('message.forgetwdname')
        this.canSend = false
      } else {
        this.nameerror = ''
        this.canSend = true
      }

      if (!this.emailBody.Email) {
        this.emailerror = this.$t('message.forgetwdemail')
        this.canSend = false
      } else {
        this.emailerror = ''
        this.canSend = true
      }
    },
    handleInfo () {
      this.$bvModal.hide('forgot-password-modal')
      this.$router.push('/BTCInfo')
    },
    handleHide () {
      this.$bvModal.hide('forgot-password-modal')
    },
    handleContactUs () {
      this.$bvModal.hide('forgot-password-modal')
      this.$router.push('/ContactUs')
    }
  }
}
</script>

<style>
#forgot-password-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #535353;
  max-width: 1013px;
  height: 90vh;
}

#forgot-password-modal .modal-content {
  border-radius: 20px;
}

#forgot-password-modal .modal-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

#forgot-password-modal .modal-footer {
  display: none;
}

#forgot-password-modal .modal-header {
  display: none;
}
</style>

<style scoped>

.name-email {
  font-size: 16px;
  line-height: 21px;
}

.name-email > div > input {
  height: 35px;
  border-radius: 8px;
  border: 1px solid #707070;
  padding: 0 15px;
  font-size: 10px;
  margin-top: 10px;
}
/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
.link{
  height: 20px;
  font-size: 10px;
  color: #4285F4;
  cursor: pointer;
}

.link:hover{
  border-bottom: 1px solid #000000;
}
</style>
