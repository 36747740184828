import CryptoJS from 'crypto-js'

// export function aesEncrypt (plaintext, key, iv) {
//   const _srcs = CryptoJS.enc.Utf8.parse(plaintext)
//   const _key = CryptoJS.enc.Utf8.parse(key)
//   const _iv = CryptoJS.enc.Utf8.parse(iv)
//   return CryptoJS.AES.encrypt(_srcs, _key, {
//     iv: _iv,
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7
//   }).toString()
// }
//
// export function getRandom (min = 1000000000000000, max = 9999999999999999) {
//   min = Math.ceil(min)
//   max = Math.floor(max)
//   return Math.floor(Math.random() * (max - min)) + min
// }

export default {
  aesEncryptText: function (plaintext, key, iv) {
    const _srcs = CryptoJS.enc.Utf8.parse(plaintext)
    const _key = CryptoJS.enc.Utf8.parse(key)
    const _iv = CryptoJS.enc.Utf8.parse(iv)
    return CryptoJS.AES.encrypt(_srcs, _key, {
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
  },
  aesDecryptText: function (ciphertext) {
    const key = '98CEF24A68092885'
    const bytes = CryptoJS.AES.decrypt(ciphertext, key)
    return bytes.toString(CryptoJS.enc.Utf8)
  },
  getRandomInt: function (min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  },
  getRandom: function (min = 1000000000000000, max = 9999999999999999) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }
}
