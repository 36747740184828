<template>
  <b-modal id="MembershipsDialog" @show="handleShow">
    <div class="privacy-security">
      <span>Terms Privacy Security ?</span>
      <span class="hand" @click="handlePrivacySecurity">Get In Touch</span>
    </div>
    <div class="privacy-security1">
      <img src="@/assets/image/login/query.png" alt="" style="width: 16px;height: 16px;margin-top: 2px;">
      <div class="hand btc-member" @click="read(url)">How to become a BTC Member?</div>
    </div>
    <img class="arrows hand" src="@/assets/image/login/memberships-arrows.png" @click="handleMemberships" alt="">
<!--    <img class="arrows1 hand" src="@/assets/image/login/memberships-arrows.png" @click="handleMemberships1" alt="">-->
  </b-modal>
</template>

<script>
export default {
  data () {
    return {
      url: `${process.env.VUE_APP_STATIC}CnPlantIcon/pdf/BTCMembership.pdf`
    }
  },
  computed: {},
  methods: {
    handleShow () {
      // 禁止页面缩放
      // const keyCodeMap = {
      //   // 91: true, // command
      //   61: true,
      //   107: true, // 数字键盘 +
      //   109: true, // 数字键盘 -
      //   173: true, // 火狐 - 号
      //   187: true, // +
      //   189: true // -
      // }
      // // 覆盖ctrl||command + ‘+’/‘-’
      // document.onkeydown = function (event) {
      //   const e = event || window.event
      //   const ctrlKey = e.ctrlKey || e.metaKey
      //   if (ctrlKey && keyCodeMap[e.keyCode]) {
      //     e.preventDefault()
      //   } else if (e.detail) { // Firefox
      //     event.returnValue = false
      //   }
      // }
      // // 覆盖鼠标滑动
      // document.body.addEventListener('wheel', (e) => {
      //   if (e.ctrlKey) {
      //     if (e.deltaY < 0) {
      //       e.preventDefault()
      //       return false
      //     }
      //     if (e.deltaY > 0) {
      //       e.preventDefault()
      //       return false
      //     }
      //   }
      // }, { passive: false })
    },
    handleMemberships () {
      this.$bvModal.hide('MembershipsDialog')
      this.sendReq('_vip_member_en', 'Pay Regular')
    },

    handleMemberships1 () {
      this.$bvModal.hide('MembershipsDialog')
      this.sendReq('_vip_member_en', 'Pay Business')
    },

    sendReq (level, type) {
      this.$store.dispatch('SetMembershipReqParams', {
        RequireLevel: level,
        RequireType: type,
        isEnRequire: true
      })
      this.$bvModal.show('MembershipsApplyDialog')
    },
    handlePrivacySecurity () {
      this.$bvModal.hide('MembershipsDialog')
      this.$router.push('/ContactUs')
    },
    read (url) {
      window.open(url)
    }
  }
}
</script>

<style>
#MembershipsDialog {
  padding: 0;
}

#MembershipsDialog.modal {
  height: auto;
  top: unset;
  bottom: 0 !important;
}

#MembershipsDialog .modal-dialog {
  background-color: #fff0;
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

#MembershipsDialog .modal-content {
  background-color: #fff0;
  border: none;
}

#MembershipsDialog .modal-content .close {
  color: #ffffff;
  font-size: 50px;
}

#MembershipsDialog .modal-header {
  height: 96px;
  width: 960px;
  background-position: 50% 50%;
  background-size: 200%;
  background-image: url(../../assets/image/login/memberships-head.png);
  background-repeat: no-repeat;
  margin-left: 480px;
  margin-bottom: -20px;
  border: none;
  padding: 0;
  z-index: 999;
}

#MembershipsDialog .modal-body {
  height: 717px;
  background-position: 50% 50%;
  background-size: 100%;
  background-image: url(../../assets/image/login/memberships-body.png);
  background-repeat: no-repeat;
  padding: 0;
}

#MembershipsDialog .modal-footer {
  display: none;
}

@media screen and (min-width: 1920px) {
  #MembershipsDialog .modal-header {
    height: 96px;
    width: 960px;
    margin-left: 480px;
  }

  #MembershipsDialog .modal-body {
    height: 717px;
  }
}

@media screen and (min-width: 2048px) {
  #MembershipsDialog .modal-header {
    height: 102px;
    width: 1024px;
    margin-left: 721px;
  }

  #MembershipsDialog .modal-body {
    height: 950px;
  }
}
</style>

<style scoped>

.arrows {
  width: 64px;
  height: 46px;
  position: absolute;
  bottom: 64.5%;
  right: 31.5%;
}

.arrows1 {
  width: 97px;
  height: 69px;
  position: absolute;
  bottom: 24.5%;
  right: 30.5%;
}

.privacy-security{
  font-size: 10px;
  color: #999999;
  position: absolute;
  bottom: 5%;
  right: 29%;
}

.privacy-security1{
  width: 190px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  font-size: 10px;
  position: absolute;
  bottom: 5%;
  right: 61.3%;
}

@media screen and (min-width: 2048px) {
  .privacy-security{
    width: 250px;
    font-size: 14px;
    right: 28.3%;
  }
  .privacy-security1{
    width: 225px;
    font-size: 14px;
    right: 62.6%;
  }
  .arrows {
    width: 97px;
    height: 69px;
  }
}

.btc-member{
  height: 20px;
  color: #4285F4;
}

.btc-member:hover{
  border-bottom: 1px solid #000000;
}

.privacy-security > span:last-child{
  margin-left: 10px;
  color: #4285F4;
}

.privacy-security > span:last-child:hover{
  border-bottom: 1px solid black;
}
</style>
