import axios from 'axios'
import store from '../store'
import { getToken } from './auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 100 * 1000 // 请求超时时间
})

// request 拦截器
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      // 让每个请求携带自定义token
      config.headers['X-Token'] = getToken()
      config.headers.Authorization = 'f3db914f-eb9d-4ddb-9e9f-f01f2beed0c0'
    }
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With,Content-Type'
    config.headers['Access-Control-Allow-Methods'] = 'PUT,POST,GET,DELETE,OPTIONS'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.IsSuccess !== true) {
      return Promise.reject(res)
    } else {
      return response.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
