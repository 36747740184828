<template>
  <section class="app-main">
    <router-view style="margin-top: 105px;"/>
    <div v-if="showQRCode" class="body-left"><img class="title-img" src="@/assets/image/beefapp_code.png" />
    <div class="code-cancel" @click="handleClickCancel()"></div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  props: {
    isShowQRCode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showQRCode: true
    }
  },
  watch: {
    isShowQRCode (newVal) {
      this.showQRCode = false
    }
  },
  methods: {
    handleClickCancel () {
      this.$emit('tab-cancel', false)
    }
  }
}
</script>
<style scoped>
.app-main {
  /* min-height: calc(100vh - 492px); */
  overflow: hidden;
}
.body-left{
    color: #717375;
    display: block !important;
    position: fixed;
    text-align: center;
    top: 250px;
    z-index: 999;
    float: right;
    right: 5px;
}
.bodyvisual-left{
    color: #717375;
    display: block !important;
    position: fixed;
    text-align: center;
    top: 510px;
    z-index: 999;
    float: right;
    right: 5px;
}
.code-cancel{
  position: fixed;
  width: 23px;
  height: 23px;
  top:250px;
  /* left: 135px; */
  right: 5px;
  cursor: pointer;
}

.code-cancel1{
  position: fixed;
  width: 23px;
  height: 23px;
  top:510px;
  /* left: 135px; */
  right: 5px;
  cursor: pointer;
}

.title-img{
  width: 160px;
}
</style>
