const key = 'btcfrontend'
const localkey = 'btcfrontendlocal'
const localToken = 'btcfrontendlocaltoken'

// sessionStorage
export function setStore (value) {
  const obj = Object.assign(getStore(), value)
  sessionStorage.setItem(key, JSON.stringify(obj))
}

export function getStore () {
  return JSON.parse(sessionStorage.getItem(key)) || {}
}

export function delStore () {
  sessionStorage.removeItem(key)
}

export function clearStore () {
  sessionStorage.clear()
}

// localStorage
export function setLocalStore (value) {
  const obj = Object.assign(getLocalStore(), value)
  localStorage.setItem(localkey, JSON.stringify(obj))
}

export function getLocalStore () {
  return JSON.parse(localStorage.getItem(localkey)) || {}
}

export function delLocalStore () {
  localStorage.removeItem(localkey)
}

// charge storage
export function setChargeStore (key, value) {
  const obj = Object.assign(getLocalStore(), value)
  localStorage.setItem(key, JSON.stringify(obj))
}

export function getChargeStore (key) {
  return JSON.parse(localStorage.getItem(key)) || {}
}

export function delChargeStore (key) {
  localStorage.removeItem(key)
}

export function getLocalToken () {
  return localStorage.getItem(localToken)
}

export function setLocalToken (key) {
  return localStorage.setItem(localToken, key)
}

export function removeLocalToken () {
  return localStorage.removeItem(localToken)
}
