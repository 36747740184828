import axios from 'axios'
import request from '@/utils/request'
import { btcguid, getToken } from '@/utils/auth'
import crypto from '@/utils/crypto'

export default {
  Login: function (userName, password) {
    const key = crypto.getRandomInt(1000000000000000, 9999999999999999)
    const iv = crypto.getRandomInt(1000000000000000, 9999999999999999)
    // eslint-disable-next-line promise/param-names
    return new Promise((receive, reject) => {
      axios({
        method: 'post',
        // TODO:Porduction
        // url: 'http://jason235.synology.me:10081/api/SystemApi/UserLogin',
        // url: 'http://localhost:8888/api/SystemApi/UserLogin',
        url: 'https://manage.beeftochina.com.cn/api/SystemApi/UserLogin',
        headers: {
          'X-Token': getToken() || '',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'X-Requested-With,Content-Type',
          'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS'
        },
        data: {
          MessageType: 6000000001,
          MessageID: btcguid(),
          Data: {
            UserName: userName,
            Password: `${key}${iv}${crypto.aesEncryptText(password, key, iv)}`
          }
        }
      }).then((res) => {
        if (res.data.IsSuccess) {
          receive(res.data)
        } else {
          reject(res.data)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  },
  UserSignUp: function (params) {
    return request({
      url: '/UserSignUp',
      method: 'post',
      data: {
        MessageType: 6000000002,
        MessageID: btcguid(),
        Data: {
          LoginUserName: params.username,
          Password: params.password,
          CompanyName: params.companyname,
          CompanyLocalName: params.companylocalname,
          CompanyType: params.companytype,
          ProductType: params.producttype,
          CompanyCountry: params.country,
          CompanyProvince: params.province,
          ContactPersonName: params.contactpersonname,
          ContactPersonSex: params.contactpersonsex,
          ContactPersonEnglishName: params.contactpersonenglishname,
          CompanyPhoneNumber: params.companyphone,
          CompanyFaxNumber: params.companyfax,
          ContactPersonCellPhoneNumber: params.cellphone,
          CompanyEmail: params.companyemail,
          CompanyWebSite: params.website
        }
      }
    })
  },

  GetUserInfo: function (username) {
    return request({
      url: '/GetUserInfo',
      method: 'post',
      data: {
        MessageType: 6000000003,
        MessageID: btcguid(),
        Data: {
          LoginUserName: username
        }
      }
    })
  },

  GetUserProfile: function (username) {
    return request({
      url: '/GetUserProfile',
      method: 'post',
      data: {
        MessageType: 6000000004,
        MessageID: btcguid(),
        Data: {
          UserID: username
        }
      }
    })
  },

  ChangePassword: function (params) {
    return request({
      url: '/ChangePassword',
      method: 'post',
      data: {
        MessageType: 6000000005,
        MessageID: btcguid(),
        Data: {
          UserID: params.userid,
          Password: params.password,
          NewPassword: params.newpassword
        }
      }
    })
  },

  UpdateUserInfo: function (params) {
    return request({
      url: '/UpdateUserInfo',
      method: 'post',
      data: {
        MessageType: 6000000006,
        MessageID: btcguid(),
        Data: {
          UserID: params.userid,
          CompanyName: params.CompanyName,
          CompanyLocalName: params.CompanyLocalName,
          CompanyType: params.CompanyType,
          ProductType: params.ProductType,
          CompanyCountry: params.CompanyCountry,
          CompanyProvince: params.CompanyProvince,
          CompanyPhoneNumber: params.CompanyPhoneNumber,
          CompanyFaxNumber: params.CompanyFaxNumber,
          CompanyEmail: params.CompanyEmail,
          CompanyWebSite: params.CompanyWebSite,
          ContactPersonName: params.ContactPersonName,
          ContactPersonSex: params.ContactPersonSex,
          ContactPersonEnglishName: params.ContactPersonEnglishName,
          ContactPersonCellPhoneNumber: params.ContactPersonCellPhoneNumber,
          NickName: params.NickName
        }
      }
    })
  },

  MembershipRequest: function (params) {
    return request({
      url: '/MembershipRequest',
      method: 'post',
      data: {
        MessageType: 6000000007,
        MessageID: btcguid(),
        Data: {
          UserID: params.UserID,
          UserName: params.UserName,
          RequireLevel: params.RequireLevel,
          RequireType: params.RequireType,
          RequireTypeT: params.RequireTypeT,
          isCnRequire: params.isCnRequire,
          isEnRequire: params.isEnRequire
        }
      }
    })
  },

  GetCaptcha: function (mobile) {
    return request({
      url: '/GetCaptcha',
      method: 'post',
      data: {
        MessageType: 6000000008,
        MessageID: btcguid(),
        Data: {
          Mobile: mobile
        }
      }
    })
  },

  CheckCaptcha: function (params) {
    return request({
      url: '/CheckCaptcha',
      method: 'post',
      data: {
        MessageType: 6000000009,
        MessageID: btcguid(),
        Data: {
          Mobile: params.mobile,
          Code: params.code
        }
      }
    })
  },

  SendMail: function (params) {
    return request({
      url: '/SendMail',
      method: 'post',
      data: {
        MessageType: 6000000010,
        MessageID: btcguid(),
        Data: {
          Name: params.Name,
          CompanyName: params.CompanyName,
          Phone: params.Phone,
          Email: params.Email,
          Content: params.Content
        }
      }
    })
  },

  ForgetWebPassword: function (params) {
    return request({
      url: '/ForgetWebPassword',
      method: 'post',
      data: {
        MessageType: 6000000011,
        MessageID: btcguid(),
        Data: {
          Name: params.Name,
          Email: params.Email,
          isEn: params.isEn
        }
      }
    })
  },

  ResetPasswordByEmail: function (params) {
    return request({
      url: '/ResetPasswordByEmail',
      method: 'post',
      data: {
        MessageType: 6000000012,
        MessageID: btcguid(),
        Data: {
          NewPassword: params.NewPassword,
          LoginUserName: params.LoginUserName,
          UpdateTime: params.UpdateTime
        }
      }
    })
  },

  CheckUserName: function (username) {
    return request({
      url: '/CheckUserName',
      method: 'post',
      data: {
        MessageType: 6000000013,
        MessageID: btcguid(),
        Data: {
          LoginUserName: username
        }
      }
    })
  },

  CheckRegEmail: function (email) {
    return request({
      url: '/CheckRegEmail',
      method: 'post',
      data: {
        MessageType: 6000000014,
        MessageID: btcguid(),
        Data: {
          CompanyEmail: email
        }
      }
    })
  },

  GetWxTicket: function () {
    return request({
      url: '/GetWxTicket',
      method: 'post',
      data: {
        MessageType: 6000000015,
        MessageID: btcguid(),
        Data: {}
      }
    })
  },

  CheckDuplicatePhone: function (phone) {
    return request({
      url: '/CheckDuplicatePhone',
      method: 'post',
      data: {
        MessageType: 6000000016,
        MessageID: btcguid(),
        Data: {
          CompanyPhoneNumber: phone
        }
      }
    })
  },

  GetUserProfileByToken: function (token) {
    return request({
      url: '/GetUserProfileByToken',
      method: 'post',
      data: {
        MessageType: 6000000017,
        MessageID: btcguid(),
        Data: {
          Token: token
        }
      }
    })
  },

  GetFrontUserInfoByUserID: function (params) {
    return request({
      url: '/GetFrontUserInfoByUserID',
      method: 'post',
      data: {
        MessageType: 6000000018,
        MessageID: btcguid(),
        Data: {
          UserID: params.UserID
        }
      }
    })
  },
  UpdateUserActivityByToken: function (token) {
    // eslint-disable-next-line promise/param-names
    return new Promise((receive, reject) => {
      axios({
        method: 'post',
        // url: 'http://192.168.10.97:5000/api/SystemApi/UpdateUserActivityByToken',
        url: 'https://manage.beeftochina.com.cn/api/SystemApi/UpdateUserActivityByToken',
        headers: {
          'X-Token': getToken() || '',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'X-Requested-With,Content-Type',
          'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS'
        },
        data: {
          MessageType: 6000000019,
          MessageID: btcguid(),
          Data: {
            Token: token
          }
        }
      }).then((res) => {
        receive(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  UploadAvator: function (params) {
    const formData = new FormData()
    formData.append('MessageType', 6000000020)
    formData.append('MessageID', btcguid())
    formData.append('Files', params.Files)
    formData.append('UserID', params.UserID)
    return request({
      url: '/UploadAvator',
      method: 'post',
      data: formData
    })
  }

  // getCaptchaImage: function () {
  //   return new Promise((receive, reject) => {
  //     axios({
  //       method: 'post',
  //       url: 'http://192.168.10.87:5000/api/SystemApi/GetCaptchaImage',
  //       responseType: 'blob',
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Headers': 'X-Requested-With,Content-Type',
  //         'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS'
  //       },
  //       data: {
  //         MessageType: 6000000020,
  //         MessageID: btcguid(),
  //         Data: {
  //         }
  //       }
  //     }).then((res) => {
  //       receive(res.data)
  //     }).catch((err) => {
  //       reject(err)
  //     })
  //   })
  // }

}
