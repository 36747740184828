import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  GetWebMarket: function (params) {
    return request({
      url: '/GetWebMarket',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000001,
        Data: params
      }
    })
  },
  GetWebFrom: function (params) {
    return request({
      url: '/GetWebFrom',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000002,
        Data: params
      }
    })
  },
  SendFuturesEmail: function (params) {
    return request({
      url: '/SendFuturesEmail',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000003,
        Data: params
      }
    })
  },
  GetWebChatList: function (params) {
    return request({
      url: '/GetWebChatList',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000004,
        Data: params
      }
    })
  },
  GetWebChatItem: function (params) {
    return request({
      url: '/GetWebChatItem',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000004,
        Data: params
      }
    })
  }
}
