import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  AddWebPlantSearchInfo: function (params) {
    return request({
      url: '/AddWebPlantSearchInfo',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000001,
        Data: params
      }
    })
  },
  GetWebApprovePlantStateOrigin: function (params) {
    return request({
      url: '/GetWebApprovePlantStateOrigin',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000002,
        Data: params
      }
    })
  },
  GetWebApprovePlantStatePlantNO: function (params) {
    return request({
      url: '/GetWebApprovePlantStatePlantNO',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000003,
        Data: params
      }
    })
  },
  GetWebApprovePlantState: function (params) {
    return request({
      url: '/GetWebApprovePlantState',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000004,
        Data: params
      }
    })
  },
  GetWebApprovePlantChildState: function (params) {
    return request({
      url: '/GetWebApprovePlantChildState',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000005,
        Data: params
      }
    })
  },
  GetWebFrontApprovePlantOrigin: function (params) {
    return request({
      url: '/GetWebFrontApprovePlantOrigin',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000006,
        Data: params
      }
    })
  },
  GetWebFrontApprovePlantNo: function (params) {
    return request({
      url: '/GetWebFrontApprovePlantNo',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000007,
        Data: params
      }
    })
  },
  GetWebFrontApprovePlantProvince: function (params) {
    return request({
      url: '/GetWebFrontApprovePlantProvince',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000008,
        Data: params
      }
    })
  },
  GetWebFrontApprovePlant: function (params) {
    return request({
      url: '/GetWebFrontApprovePlant',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000009,
        Data: params
      }
    })
  },
  GetWebFrontApprovePlantPattern: function (params) {
    return request({
      url: '/GetWebFrontApprovePlantPattern',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000010,
        Data: params
      }
    })
  },
  GetWebHistoricalUpdate: function (params) {
    return request({
      url: '/GetWebHistoricalUpdate',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000011,
        Data: params
      }
    })
  },
  GetFrontPorkDataEstItems: function (params) {
    return request({
      url: '/GetFrontPorkDataEstItems',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000012,
        Data: params
      }
    })
  },
  GetWebFrontPorkDataEst: function (params) {
    return request({
      url: '/GetWebFrontPorkDataEst',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 4000000013,
        Data: params
      }
    })
  }
}
