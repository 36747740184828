import api from '@/api/market'

export default {
  state: {
    total: 0,
    row: {},
    dict: {},
    item: {},
    rows: []
  },

  mutations: {
    SET_TOTAL: (state, total) => {
      state.total = total
    },
    SET_ROW: (state, row) => {
      state.row = row
    },
    SET_ROWS: (state, rows) => {
      state.rows = rows
    },
    SET_DICT: (state, dict) => {
      state.dict = dict
    },
    SET_ITEM: (state, item) => {
      state.item = item
    }
  },
  actions: {
    GetWebMarket ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebMarket(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          commit('SET_TOTAL', response.Data.Total)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebFrom ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrom(params).then(response => {
          commit('SET_DICT', response.Data.Dict)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    SendFuturesEmail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.SendFuturesEmail(params).then(response => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebChatList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebChatList(params).then(response => {
          commit('SET_DICT', response.Data.Dict)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebChatItem ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebChatItem(params).then(response => {
          commit('SET_ITEM', response.Data.Dict)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
