<template>
  <b-modal id="CnMembershipsDialog" @show="handleShow">
    <div class="privacy-security">
<!--      <span>Terms Privacy Security ?</span>-->
      <span class="hand" @click="handlePrivacySecurity">联系我们</span>
    </div>
<!--    <div class="privacy-security1">-->
<!--      <img src="@/assets/image/login/query.png" alt="" style="width: 16px;height: 16px;margin-top: 2px;">-->
<!--      <div class="hand btc-member" @click="read(url)">How to become a BTC Member?</div>-->
<!--     </div> -->
<!--    <img class="arrows hand" src="@/assets/image/cn/shenqing.png" @click="handleMemberships" alt="">
    <img class="arrows1 hand" src="@/assets/image/cn/jiaru.png" @click="handleMemberships1" alt=""> -->
   <span style="text-align: center;align-items: center;width: 100%;margin: 0 auto;justify-content: center;bottom: 10%;position: absolute;">
      <span class="content-item">
        <span>
          <img class="arrows2" src="@/assets/image/cn/1.png">
        </span>
        <span style="">
          <img class="ontrial" src="@/assets/image/cn/ontrial.png"  @click="handleMemberships">
        </span>
      </span>
      <!--
      <span class="content-item">
        <img class="arrows3" src="@/assets/image/cn/2.png">
        <img class="ontrial" src="@/assets/image/cn/activatenow.png"  @click="handleMemberships1(2000)">
      </span>
      -->
      <span class="content-item">
        <img class="arrows4" src="@/assets/image/cn/3.png">
        <img class="ontrial" src="@/assets/image/cn/activatenow.png"  @click="handleMemberships1(4000)">
      </span>
      <span class="content-item">
        <img class="arrows5" src="@/assets/image/cn/4.png">
        <img class="ontrial" src="@/assets/image/cn/activatenow.png"  @click="handleMemberships1(8000)">
      </span>
    </span>

<!--    <div style="display: flex;margin: 0 auto;">
      <span>
        <ul>
          <li style="list-style: none;"><img class="arrows2" src="@/assets/image/cn/1.png"></li>
          <li style="list-style: none;"><img class="ontrial" src="@/assets/image/cn/ontrial.png"  @click="handleMemberships"></li>
        </ul>
      </span>
      <span>
        <ul>
          <li style="list-style: none;"> <img class="arrows3" src="@/assets/image/cn/2.png"></li>
          <li style="list-style: none;"><img class="ontrial" src="@/assets/image/cn/ontrial.png"  @click="handleMemberships"></li>
        </ul>
      </span>
      <span>
        <ul>
          <li style="list-style: none;"> <img class="arrows4" src="@/assets/image/cn/3.png"></li>
          <li style="list-style: none;"><img class="ontrial" src="@/assets/image/cn/ontrial.png"  @click="handleMemberships"></li>
        </ul>
      </span>
      <span>
        <ul>
          <li style="list-style: none;"><img class="arrows5" src="@/assets/image/cn/4.png"></li>
          <li style="list-style: none;"><img class="ontrial" src="@/assets/image/cn/ontrial.png"  @click="handleMemberships"></li>
        </ul>
      </span>
    </div> -->

   <!-- <div class="vip-box">
      <div class="content-item">
        <div>
          <img class="arrows2" src="@/assets/image/cn/1.png">
        </div>
        <div>
          <img class="ontrial" src="@/assets/image/cn/ontrial.png"  @click="handleMemberships">
        </div>
      </div>
      <div class="content-item">
        <div>
          <img class="arrows3" src="@/assets/image/cn/2.png">
        </div>
        <div>
           <img class="button1" src="@/assets/image/cn/activatenow.png">
        </div>
      </div>
      <div class="content-item">
        <div>
          <img class="arrows4" src="@/assets/image/cn/3.png">
        </div>
        <div>
           <img class="button1" src="@/assets/image/cn/activatenow.png" @click="handleMemberships(4000)">
        </div>
      </div>
      <div class="content-item">
        <div>
          <img class="arrows5" src="@/assets/image/cn/4.png">
        </div>
        <div>
           <img class="button1" src="@/assets/image/cn/activatenow.png" @click="handleMemberships1(8000)">
        </div>
      </div>
    </div> -->
<!--    <div class="content-box">
    <img class="arrows2" src="@/assets/image/cn/1.png" @click="handleMemberships">
    <img class="button1" src="@/assets/image/cn/ontrial.png" >
    <img class="arrows3" src="@/assets/image/cn/2.png" @click="handleMemberships1">
    <img class="button1" src="@/assets/image/cn/activatenow.png" >
    <img class="arrows4" src="@/assets/image/cn/3.png" @click="handleMemberships">
    <img class="arrows5" src="@/assets/image/cn/4.png" @click="handleMemberships1">
    </div> -->
  </b-modal>
</template>

<script>
export default {
  data () {
    return {
      url: `${process.env.VUE_APP_STATIC}CnPlantIcon/pdf/BTCMembership.pdf`
    }
  },
  computed: {},
  methods: {
    handleShow () {
      // 禁止页面缩放
      // const keyCodeMap = {
      //   // 91: true, // command
      //   61: true,
      //   107: true, // 数字键盘 +
      //   109: true, // 数字键盘 -
      //   173: true, // 火狐 - 号
      //   187: true, // +
      //   189: true // -
      // }
      // // 覆盖ctrl||command + ‘+’/‘-’
      // document.onkeydown = function (event) {
      //   const e = event || window.event
      //   const ctrlKey = e.ctrlKey || e.metaKey
      //   if (ctrlKey && keyCodeMap[e.keyCode]) {
      //     e.preventDefault()
      //   } else if (e.detail) { // Firefox
      //     event.returnValue = false
      //   }
      // }
      // // 覆盖鼠标滑动
      // document.body.addEventListener('wheel', (e) => {
      //   if (e.ctrlKey) {
      //     if (e.deltaY < 0) {
      //       e.preventDefault()
      //       return false
      //     }
      //     if (e.deltaY > 0) {
      //       e.preventDefault()
      //       return false
      //     }
      //   }
      // }, { passive: false })
    },
    handleMemberships () {
      this.$bvModal.hide('CnMembershipsDialog')
      this.sendReq('_vip_member', 'Free', 0)
    },

    handleMemberships1 (money) {
      this.$bvModal.hide('CnMembershipsDialog')
      this.sendReq('_vip_member', 'Pay', money)
    },

    sendReq (level, type, money) {
      this.$store.dispatch('SetMembershipReqParams', {
        RequireLevel: level,
        RequireType: type,
        isCnRequire: true,
        GradeMoney: money
      })
      if (type === 'Free') {
        this.$bvModal.show('CnMembershipsRoujiDialog')
      } else {
        this.$bvModal.show('CnMembershipsApplyDialog')
      }
    },
    handlePrivacySecurity () {
      this.$bvModal.hide('CnMembershipsDialog')
      this.$router.push('/cn/ContactUs')
    },
    read (url) {
      window.open(url)
    }
  }
}
</script>

<style>
#CnMembershipsDialog {
  padding: 0;
}

#CnMembershipsDialog.modal {
  height: auto;
  top: unset;
  bottom: 0 !important;
}

#CnMembershipsDialog .modal-dialog {
  background-color: #fff0;
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

#CnMembershipsDialog .modal-content {
  background-color: #fff0;
  border: none;
}

#CnMembershipsDialog .modal-content .close {
  color: #ffffff;
  font-size: 50px;
}

#CnMembershipsDialog .modal-header {
  height: 96px;
  width: 960px;
  background-position: 50% 50%;
  background-size: 200%;
  background-image: url(../../../assets/image/login/memberships-head.png);
  background-repeat: no-repeat;
  margin-left: 480px;
  margin-bottom: -20px;
  border: none;
  padding: 0;
  z-index: 999;
}

#CnMembershipsDialog .modal-body {
  height: 717px;
  background-position: 50% 50%;
  background-size: 100%;
  background-image: url(../../../assets/image/cn/bigbackground.png);
  background-repeat: no-repeat;
  padding: 0;
}

#CnMembershipsDialog .modal-footer {
  display: none;
}

@media screen and (min-width: 1920px) {
  #CnMembershipsDialog .modal-header {
    height: 96px;
    width: 960px;
    margin-left: 480px;
  }

  #CnMembershipsDialog .modal-body {
    height: 717px;
  }
}

@media screen and (min-width: 2048px) {
  #CnMembershipsDialog .modal-header {
    height: 102px;
    width: 1024px;
    margin-left: 721px;
  }

  #CnMembershipsDialog .modal-body {
    height: 950px;
  }
}
</style>

<style scoped>

.arrows {
  width: 96px;
  height: 69px;
  position: absolute;
  bottom: 61%;
  right: 31%;
}

.arrows1 {
  width: 96px;
  height: 69px;
  position: absolute;
  bottom: 25%;
  right: 31%;
}

.privacy-security{
  font-size: 10px;
  color: #999999;
  position: absolute;
  bottom: 8%;
  right: 29%;
}

.privacy-security1{
  width: 190px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  font-size: 10px;
  position: absolute;
  bottom: 5%;
  right: 61.3%;
}

@media screen and (min-width: 2048px) {
  .privacy-security{
    width: 250px;
    font-size: 14px;
    right: 16%;
  }
  .privacy-security1{
    width: 225px;
    font-size: 14px;
    right: 62.6%;
  }
  .arrows {
    width: 97px;
    height: 69px;
  }
}

.btc-member{
  height: 20px;
  color: #4285F4;
}

.btc-member:hover{
  border-bottom: 1px solid #000000;
}

.privacy-security > span:last-child{
  margin-left: 10px;
}

.privacy-security > span:last-child:hover{
  border-bottom: 1px solid black;
}

.vip-box{
  position: absolute;
  bottom: 25%;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
/*  width: 100%; */
  display: flex;
}

.content-item{
/*  display: block;
  width: 40%; */
  /* display: inline-block; */
/*  display: flex;
  width: 40%; */
}

.arrows2{
  /* width: 40%; */
  width: 14%;
}

.arrows3{
  /* width: 40%; */
  width: 14%;
}

.arrows4{
  /* width: 40%; */
  width: 14%;
}

.arrows5{
  width: 14%;
  /* width: 40%; */
/*  height: 17.2%; */
  margin-bottom: 1.8%;
}

.ontrial{
  width: 5%;
  /* margin-top: -20%; */
  /* bottom: -15%; */
  cursor: pointer;
  position: absolute;
  margin-left: -9.5%;
  margin-top: 21%;
  z-index: 999;
}

.button1{
  width: 15%;
  cursor: pointer;
}
</style>
