import api from '@/api/eyes'

export default {
  state: {
    total: 0,
    row: {},
    rows: [],
    isLoading: false
  },

  mutations: {
    SET_TOTAL: (state, total) => {
      state.total = total
    },
    SET_ROW: (state, row) => {
      state.row = row
    },
    SET_ROWS: (state, rows) => {
      state.rows = rows
    },
    SET_LOADING_STATUS: (state, status) => {
      state.isLoading = status
    }
  },
  actions: {
    // 设置loading状态
    SetLoadingStatus ({ commit }, status) {
      commit('SET_LOADING_STATUS', status)
    },
    GetEyes ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetEyes(params).then(response => {
          commit('SET_TOTAL', response.Data.Total)
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    AddDeleteWebEyes ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.AddDeleteWebEyes(params).then(response => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
