import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutEn from '@/views/en/layout'
import LayoutCn from '@/views/cn/layout'
import Share from '@/views/cn/sharepage'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/cn/redirect' },
  { path: '/index.html', redirect: '/cn/redirect' },
  {
    path: '/share/:id',
    component: Share,
    name: 'share',
    children: [{
      path: '',
      component: () => import('@/views/cn/sharepage')
    }]
  },

  // CN Web
  {
    path: '/cn/redirect',
    name: 'cnRedirect',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/redirect/redirect')
    }]
  },
  {
    path: '/cn/Login',
    name: 'cnLogin',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/login/index')
    }]
  },
  {
    path: '/cn/Register',
    name: 'cnRegister',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/login/register')
    }]
  },
  {
    path: '/cn/ContactUs',
    name: 'cnContactUs',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/login/get-in-touch')
    }]
  },
  {
    path: '/cn/Terms',
    name: 'cnTerms',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/login/terms-conditions')
    }]
  },
  {
    path: '/cn/PrivacyPolicy',
    name: 'cnPrivacyPolicy',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/login/privacy-policy')
    }]
  },
  {
    path: '/cn/Account',
    name: 'cnAccount',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/account')
    }]
  },
  {
    path: '/cn/ResetPassword',
    name: 'cnResetPassword',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/resetpassword')
    }]
  },
  {
    path: '/cn/Homepage',
    name: 'cnHomepage',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/homepage/index')
    }]
  },
  {
    path: '/cn/BTCEyes',
    name: 'cnBTCEyes',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btceyes/index')
    }]
  },
  {
    path: '/cn/BTCEyes/Videos',
    name: 'cnBTCEyesVideos',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btceyes/video-list')
    }]
  },
  {
    path: '/cn/BTCInfo',
    name: 'cnBTCInfo',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcinfo/index')
    }]
  },
  {
    path: '/cn/BTCInfo/Article/:id',
    name: 'cnArticle',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcinfo/info-detail')
    }]
  },
  {
    path: '/cn/BTCInfo/ArticleFree/:id',
    name: 'cnArticleFree',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcinfo/info-detail-free')
    }]
  },
  {
    path: '/cn/BTCInfo/Section',
    name: 'cnSection',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcinfo/info-more')
    }]
  },
  {
    path: '/cn/BTCInfo/Report',
    name: 'cnReport',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcinfo/report-more')
    }]
  },
  {
    path: '/cn/BTCData',
    name: 'cnBTCData',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcdata/index')
    }]
  },
  {
    path: '/cn/PorkData',
    name: 'cnPorkData',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/porkdata/index')
    }]
  },
  {
    path: '/cn/BTCPrice',
    name: 'cnBTCPrice',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcprice/index')
    }]
  },
  {
    path: '/cn/ProkPrice',
    name: 'cnPorkPrice',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/porkprice/index')
    }]
  },
  {
    path: '/cn/BTCTrend',
    name: 'cnBTCTrend',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btctrend/index')
    }]
  },
  {
    path: '/cn/BTCUser',
    name: 'cnBTCUser',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btctrend/user-trend')
    }]
  },
  {
    path: '/cn/BTCEst',
    name: 'cnBTCEst',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcest/index')
    }]
  },
  {
    path: '/cn/BTCPorkEst',
    name: 'cnBTCPorkEst',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/btcporkest/index')
    }]
  },
  {
    path: '/cn/BTCApp',
    name: 'cnBTCApp',
    component: LayoutCn,
    children: [{
      path: '',
      component: () => import('@/views/cn/app/index')
    }]
  },
  // EN Web
  {
    path: '/Login',
    name: 'Login',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/login/index')
    }]
  },
  {
    path: '/Register',
    name: 'Register',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/login/register')
    }]
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/login/get-in-touch')
    }]
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/login/terms-conditions')
    }]
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/login/privacy-policy')
    }]
  },
  {
    path: '/Account',
    name: 'Account',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/account')
    }]
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/resetpassword')
    }]
  },
  {
    path: '/Homepage',
    name: 'Homepage',
    component: LayoutEn,
    children: [{
      name: 'Homepage',
      path: '/Homepage',
      component: () => import('@/views/en/homepage/index')
    }]
  },
  {
    path: '/BTCEyes',
    name: 'BTCEyes',
    component: LayoutEn,
    children: [{
      name: 'BTCEyes',
      path: '/BTCEyes',
      component: () => import('@/views/en/btceyes/index')
    }]
  },
  {
    path: '/BTCEyes/Videos',
    name: 'BTCEyesVideos',
    component: LayoutEn,
    children: [{
      name: 'VideoList',
      path: '',
      component: () => import('@/views/en/btceyes/video-list')
    }]
  },
  {
    path: '/BTCInfo',
    name: 'BTCInfo',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcinfo/index')
    }]
  },
  {
    path: '/BTCInfo/Article/:id',
    name: 'Article',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcinfo/info-detail')
    }]
  },
  {
    path: '/BTCInfo/ArticleFree/:id',
    name: 'ArticleFree',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcinfo/info-detail-free')
    }]
  },
  {
    path: '/BTCInfo/Section',
    name: 'Section',
    component: LayoutEn,
    children: [{
      path: '',
      name: 'InfoSection',
      component: () => import('@/views/en/btcinfo/info-more')
    }]
  },
  {
    path: '/BTCInfo/Report',
    name: 'Report',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcinfo/report-more')
    }]
  },
  {
    path: '/BTCData',
    name: 'BTCData',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcdata/index')
    }]
  },
  {
    path: '/BTCPrice',
    name: 'BTCPrice',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcprice/index')
    }]
  },
  {
    path: '/Market',
    name: 'Market',
    component: LayoutEn,
    children: [{
      name: '',
      path: '',
      component: () => import('@/views/en/market/index')
    }]
  },
  {
    path: '/BTCEst',
    name: 'BTCEst',
    component: LayoutEn,
    children: [{
      path: '',
      component: () => import('@/views/en/btcest/index')
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
