<template>
  <b-row class="navbar" align-h="center">
    <ul>
      <li v-for="(item, index) in tabs" :key="index" :class="{ active: current===index }" v-on:mouseover="changeActive(index)" v-on:mouseleave="leaveActive()" tabindex="1" @click="handleClickTabMenu(item, index)">
        <a>{{ item.text }}</a>
 <!--       <div class="triangle triangle-left"></div>
        <div class="triangle triangle-right"></div> -->
        <div v-show="item.children && item.children.length >0 && index === currentActive">
          <div v-on:mouseover="changeActiveChild(index)" v-on:mouseout="removeActiveChild()" class="navbar-item">
            <div v-for="(itemChild, indexChild) in item.children" :key="indexChild" v-on:mouseover="changeActive2($event)" v-on:mouseout="removeActive2($event)"  @click="handleClickTab(itemChild, index)" class="item">
              <div>{{ itemChild.text }}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </b-row>
</template>

<script>
export default {
  name: 'CNNavbar',
  data () {
    return {
      isEnter: false,
      showCurrent: 0,
      currentActive: -1,
      current: 1,
      tabs: [
        { text: '首页', href: '/cn/Homepage' },
        {
          text: '必孚信息',
          href: '/cn/BTCInfo',
          children: [
            {
              text: '图文',
              href: '/cn/BTCInfo'
            },
            {
              text: '视频',
              href: '/cn/BTCEyes'
            }
          ]
        },
        {
          text: '必孚数据 ',
          href: '/cn/BTCData',
          children: [
            {
              text: '牛肉',
              href: '/cn/BTCData'
            },
            {
              text: '猪肉',
              href: '/cn/PorkData'
            }
          ]
        },
        {
          text: '必孚行情',
          href: '/cn/BTCPrice'
          // children: [
          //   {
          //     text: '牛肉',
          //     href: '/cn/BTCPrice'
          //   },
          //   {
          //     text: '猪肉',
          //     href: '/cn/ProkPrice'
          //   }
          // ]
        },
        {
          text: '必孚工厂',
          href: '/cn/BTCEst',
          children: [
            {
              text: '牛肉',
              href: '/cn/BTCEst'
            },
            {
              text: '猪肉',
              href: '/cn/BTCPorkEst'
            }
          ]
        },
        { text: '必孚云展', href: '/cn/BTCTrend' },
        { text: '必孚商户', href: '/cn/BTCUser' },
        { text: '必孚App', href: '/cn/BTCApp' }
      ],
      isClick: false
    }
  },
  mounted () {
    // this.current = parseInt(sessionStorage.getItem('navindex')) || 2
    if (this.handCurrent() === 0) {
      this.current = 0
    } else if (this.handCurrent()) {
      this.current = this.handCurrent()
    } else {
      this.current = 1
    }
  },
  watch: {
    $route (to, from) {
      this.tabs.forEach((item, index) => {
        if (item.href === to.path) {
          this.current = index
        } else if (item.href === '') {
          if (item != null && item.children != null && item.children.length > 0) {
            const itemChild = item.children.find(i => i.href === to.path)
            if (itemChild) {
              this.current = index
            }
          }
        }
      })
    }
  },
  methods: {
    changeActive (index) {
      this.currentActive = index
      this.showCurrent = 1
      this.isClick = true
    },
    changeActive2 ($event) {
      $event.currentTarget.className = 'item-active'
    },
    removeActive2 ($event) {
      $event.currentTarget.className = 'item'
    },
    changeActiveChild (val) {
      this.currentActive = val
      this.isEnter = true
      this.showCurrent = 1
      this.isClick = false
    },
    removeActiveChild () {
      this.currentActive = -1
    },
    leaveActive () {
      if (this.showCurrent === 1 && this.isEnter === false) {
        this.currentActive = -1
      }
      if (this.showCurrent === 1 && this.isEnter === true) {
        this.currentActive = -1
      }
      this.isClick = false
    },
    enterActive () {
      this.showCurrent = 1
    },
    handleClickTab (item, index) {
      if (!item.children) {
        this.$router.push(item.href)
        this.current = index
        sessionStorage.setItem('navindex', index)
        this.$emit('tab-handCurrent', true)
        this.$emit('tab-visualCode', true)
      }
      this.isClick = false
    },
    handleClickTabMenu (item, index) {
      if (this.isClick) {
        this.$router.push(item.href)
        this.current = index
        sessionStorage.setItem('navindex', index)
        this.$emit('tab-handCurrent', true)
        this.$emit('tab-visualCode', true)
      }
    },
    handCurrent () {
      let currentNow = 1
      this.tabs.forEach((item, index) => {
        if (item.href === this.$route.path) {
          currentNow = index
        } else if (item.href === '') {
          if (item != null && item.children != null && item.children.length > 0) {
            const itemChild = item.children.find(i => i.href === this.$route.path)
            if (itemChild) {
              currentNow = index
            }
          }
        }
      })
      return currentNow
    }
  }
}
</script>

<style lang="scss" scoped>
$tab-color: #ffffff;
$tab-active-color: #006eb9;
// 导航
.navbar {
  min-width: 1248px;
  margin: 0;
  padding: 0;
  background: #f9f9fa;
}
.navbar > ul {
  margin: 0;
  padding: 10px 0 0 0;
  list-style: none;
}
.navbar > ul > li {
  float: left;
  width: 116px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  margin: 0 10px;
  background: $tab-color;
  border-radius: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
  outline: none;
}

.navbar > ul > li > a {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}

// 三角形
.triangle {
  width: 0;
  height: 0;
  position: absolute;
  border: 5px solid $tab-color;
  border-top-color: transparent!important;
  bottom: 0;
}
.triangle-left {
  border-left-color: transparent!important;
  left: -5px;
}
.triangle-right {
  border-right-color: transparent!important;
  right: -5px;
}

// 点击
.active {
  background-color: $tab-active-color!important;
}
.active > a {
  color: #ffffff!important;
}
.active > .triangle-left, .active > .triangle-right {
  border: 5px solid $tab-active-color;
}

.navbar-item{
  width: 116px;
  // height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  position: fixed;
  // margin-top: 5px;
}

.item{
  background: #FFFFFF;
  color: #4E4E4E !important;
  border-radius: 3px;
  line-height: 45px;
}

.item-active{
  background: #006eb9;
  color: #FFFFFF !important;
  border-radius: 3px;
  line-height: 45px;
}
</style>
