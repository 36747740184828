import api from '@/api/price'

export default {
  state: {
    row: {},
    rows: [],
    country: [],
    cuts: [],
    date: [],
    total: 0
  },

  mutations: {
    SET_ROW: (state, row) => {
      state.row = row
    },
    SET_ROWS: (state, rows) => {
      state.rows = rows
    },
    SET_COUNTRY: (state, country) => {
      state.country = country
    },
    SET_CUTS: (state, cuts) => {
      state.cuts = cuts
    },
    SET_DATE: (state, date) => {
      state.date = date
    },
    SET_TOTAL: (state, total) => {
      state.total = total
    }
  },
  actions: {
    GetWebPriceDataList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPriceDataList(params).then(response => {
          commit('SET_CUTS', response.Data.Rows)
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebFrontPriceDictionary ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontPriceDictionary(params).then(response => {
          commit('SET_COUNTRY', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPorkFrontPriceDictionary ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPorkFrontPriceDictionary(params).then(response => {
          commit('SET_COUNTRY', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPriceVaildDate ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPriceVaildDate(params).then(response => {
          commit('SET_DATE', response.Data.List)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPriceDataIndex ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPriceDataIndex(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetPorkPriceDataIndex ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetPorkPriceDataIndex(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebPriceLivecattleIndex ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebPriceLivecattleIndex(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebFrontMarketReport ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontMarketReport(params).then(response => {
          commit('SET_TOTAL', response.Data.Total)
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebNewsAnalysis ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebNewsAnalysis(params).then(response => {
          commit('SET_TOTAL', response.Data.Total)
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebBeefPriceDataResearchList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebBeefPriceDataResearchList(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebBeefPriceDataIndex ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebBeefPriceDataIndex(params).then(response => {
          commit('SET_ROWS', response.Data.Dict)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebBeefPriceDataResearchIndex ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebBeefPriceDataResearchIndex(params).then(response => {
          commit('SET_ROWS', response.Data.Dict)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    AddWebBeefPriceDataOpinion ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.AddWebBeefPriceDataOpinion(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
