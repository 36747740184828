import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  GetWebPriceDataList: function (params) {
    return request({
      url: '/GetWebPriceDataList',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000001,
        Data: params
      }
    })
  },
  GetWebFrontPriceDictionary: function (params) {
    return request({
      url: '/GetWebFrontPriceDictionary',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000002,
        Data: params
      }
    })
  },
  GetWebPorkFrontPriceDictionary: function (params) {
    return request({
      url: '/GetWebPorkFrontPriceDictionary',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000008,
        Data: params
      }
    })
  },
  GetWebPriceVaildDate: function (params) {
    return request({
      url: '/GetWebPriceVaildDate',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000003,
        Data: params
      }
    })
  },
  GetWebPriceDataIndex: function (params) {
    return request({
      url: '/GetWebPriceDataIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000004,
        Data: params
      }
    })
  },
  GetPorkPriceDataIndex: function (params) {
    return request({
      url: '/GetPorkPriceDataIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000008,
        Data: params
      }
    })
  },
  GetWebPriceLivecattleIndex: function (params) {
    return request({
      url: '/GetWebPriceLivecattleIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000005,
        Data: params
      }
    })
  },
  GetWebFrontMarketReport: function (params) {
    return request({
      url: '/GetWebFrontMarketReport',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000006,
        Data: params
      }
    })
  },
  GetWebNewsAnalysis: function (params) {
    return request({
      url: '/GetWebNewsAnalysis',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000007,
        Data: params
      }
    })
  },
  GetWebBeefPriceDataResearchList: function (params) {
    return request({
      url: '/GetWebBeefPriceDataResearchList',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000008,
        Data: params
      }
    })
  },
  GetWebBeefPriceDataIndex: function (params) {
    return request({
      url: '/GetWebBeefPriceDataIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000009,
        Data: params
      }
    })
  },
  GetWebBeefPriceDataResearchIndex: function (params) {
    return request({
      url: '/GetWebBeefPriceDataResearchIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000010,
        Data: params
      }
    })
  },
  AddWebBeefPriceDataOpinion: function (params) {
    return request({
      url: '/AddWebBeefPriceDataOpinion',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000011,
        Data: params
      }
    })
  }
}
