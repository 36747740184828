<template>
  <div>
    <div class="fixed-bar">
      <pre-header/>
      <navbar @tab-handCurrent="isShowQRCodeClick" />
    </div>
    <login-dialog/>
    <memberships-dialog/>
    <memberships-apply-dialog/>
    <app-main :isShowQRCode="isShowQRCode" @tab-cancel="tabCancel"/>
    <layout-footer/>
    <forgot-password/>
    <common-dialog id="sentMembershipsDialog" message="sentmembershipsful"/>
    <div :class="{ 'z-hide': !isloading }" class="load8">
        <b-spinner class="loader"/>
    </div>
  </div>
</template>

<script>
import Navbar from './components/navbar'
import AppMain from './components/app-main'
import PreHeader from './components/preheader'
import LayoutFooter from './components/footer'
import ForgotPassword from '@/components/ForgotPassword'
import MembershipsDialog from '@/components/MembershipsDialog'
import MembershipsApplyDialog from '@/components/MembershipsDialog/apply.vue'
import CommonDialog from '@/components/CommonDialog'
import LoginDialog from '@/components/LoginDialog'

export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
    PreHeader,
    LoginDialog,
    CommonDialog,
    LayoutFooter,
    ForgotPassword,
    MembershipsDialog,
    MembershipsApplyDialog
  },
  data () {
    return {
      isShowQRCode: true
    }
  },
  computed: {
    isloading () {
      return this.$store.getters.isLoading
    }
  },
  mounted () {
    this.$i18n.locale = 'en'
  },
  methods: {
    isShowQRCodeClick () {
      this.isShowQRCode = true
    },
    tabCancel () {
      this.isShowQRCode = false
    }
  }

}
</script>

<style>
.z-hide { display: none }
</style>

<style scoped>

.fixed-bar {
  width: 100%;
  position: fixed;
  z-index: 9;
}

.load8 {
  height: auto !important;
}

.load8 .loader {
  font-size: 1px;
  position: absolute;
  margin: -75px 0 0 -75px;
  top: 50%;
  left: 50%;
  border-top: 3.1px solid rgba(60, 60, 255, 0.5);
  border-right: 3.1px solid rgba(60, 60, 255, 0.5);
  border-bottom: 3.1px solid rgba(60, 60, 255, 0.5);
  border-left: 3.1px solid transparent;
  -webkit-animation: load8 2.1s infinite linear;
  animation: load8 2.1s infinite linear;
  z-index: 9999;
  position: fixed;
}

.load8 .loader,
.load8 .loader:after {
  border-radius: 50% !important;
  width: 150px;
  height: 150px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>
