import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  // Beef Exports to China
  GetBeefWebDataOneChart: function (params) {
    return request({
      url: '/GetBeefWebDataOneChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000001,
        Data: params
      }
    })
  },
  GetBeefWebDataOneTable: function (params) {
    return request({
      url: '/GetBeefWebDataOneTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000002,
        Data: params
      }
    })
  },
  // Global Beef Exports
  GetBeefWebDataTwoChart: function (params) {
    return request({
      url: '/GetBeefWebDataTwoChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000003,
        Data: params
      }
    })
  },
  GetBeefWebDataTwoTable: function (params) {
    return request({
      url: '/GetBeefWebDataTwoTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000004,
        Data: params
      }
    })
  },
  // Slaughter by origin
  GetBeefWebDataThreeChart: function (params) {
    return request({
      url: '/GetBeefWebDataThreeChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000005,
        Data: params
      }
    })
  },
  GetBeefWebDataThreeTable: function (params) {
    return request({
      url: '/GetBeefWebDataThreeTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000006,
        Data: params
      }
    })
  },
  GetBeefWebDataFourChart: function (params) {
    return request({
      url: '/GetBeefWebDataFourChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000007,
        Data: params
      }
    })
  },
  GetBeefWebDataFourTable: function (params) {
    return request({
      url: '/GetBeefWebDataFourTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000008,
        Data: params
      }
    })
  },
  GetBeefWebDataFiveChart: function (params) {
    return request({
      url: '/GetBeefWebDataFiveChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000009,
        Data: params
      }
    })
  },
  GetBeefWebDataFiveTable: function (params) {
    return request({
      url: '/GetBeefWebDataFiveTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000010,
        Data: params
      }
    })
  },
  // China's Beef imports
  GetBeefWebDataSixChart: function (params) {
    return request({
      url: '/GetBeefWebDataSixChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000011,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          Type: params.Type,
          Storage: params.Storage
        }
      }
    })
  },
  GetBeefWebDataSixTable: function (params) {
    return request({
      url: '/GetBeefWebDataSixTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000012,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Type: params.Type,
          Storage: params.Storage,
          StartDate: params.StartDate,
          EndDate: params.EndDate
        }
      }
    })
  },
  GetBeefWebDataSevenChart: function (params) {
    return request({
      url: '/GetBeefWebDataSevenChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000013,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          PlantNo: params.PlantNo,
          Cuts: params.Cuts
        }
      }
    })
  },
  GetBeefWebDataSevenTable: function (params) {
    return request({
      url: '/GetBeefWebDataSevenTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000014,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          PlantNo: params.PlantNo,
          StartDate: params.StartDate,
          EndDate: params.EndDate
        }
      }
    })
  },
  GetBeefWebDataEightChart: function (params) {
    return request({
      url: '/GetBeefWebDataEightChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000015,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          Cuts: params.Cuts
        }
      }
    })
  },
  GetBeefWebDataEightTable: function (params) {
    return request({
      url: '/GetBeefWebDataEightTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000016,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          Cuts: params.Cuts,
          StartDate: params.StartDate,
          EndDate: params.EndDate
        }
      }
    })
  },
  GetBeefWebDataNineChart: function (params) {
    return request({
      url: '/GetBeefWebDataNineChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000017,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          PlantNo: params.PlantNo,
          Cuts: params.Cuts
        }
      }
    })
  },
  GetBeefWebDataNineTable: function (params) {
    return request({
      url: '/GetBeefWebDataNineTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000018,
        Data: {
          isEn: params.isEn,
          UserID: params.UserID,
          LanguageCode: params.LanguageCode,
          IsFirst: params.IsFirst,
          Origin: params.Origin,
          PlantNo: params.PlantNo,
          Cuts: params.Cuts,
          StartDate: params.StartDate,
          EndDate: params.EndDate
        }
      }
    })
  },
  GetOptionData: function (params) {
    return request({
      url: '/GetOptionData',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000019,
        Data: params
      }
    })
  },
  GetWebPorkNewDataFiveChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataFiveChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000020,
        Data: params
      }
    })
  },
  GetWebPorkNewDataFiveTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataFiveTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000021,
        Data: params
      }
    })
  },
  GetWebPorkNewDataTwoChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataTwoChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000022,
        Data: params
      }
    })
  },
  GetWebPorkNewDataTwoTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataTwoTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000023,
        Data: params
      }
    })
  },
  GetWebPorkNewDataOneChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataOneChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000024,
        Data: params
      }
    })
  },
  GetWebPorkNewDataOneTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataOneTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000025,
        Data: params
      }
    })
  },
  GetWebPorkNewDataThreeContrastChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataThreeContrastChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000026,
        Data: params
      }
    })
  },
  GetWebPorkNewDataThreeContrastTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataThreeContrastTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000027,
        Data: params
      }
    })
  },
  GetWebPorkNewDataSixChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataSixChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000028,
        Data: params
      }
    })
  },
  GetWebPorkNewDataSixTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataSixTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000029,
        Data: params
      }
    })
  },
  GetWebPorkNewDataThreeChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataThreeChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000029,
        Data: params
      }
    })
  },
  GetWebPorkNewDataThreeTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataThreeTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000029,
        Data: params
      }
    })
  },
  GetWebPorkNewDataFourContrastChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataFourContrastChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000030,
        Data: params
      }
    })
  },
  GetWebPorkNewDataFourContrastTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataFourContrastTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000031,
        Data: params
      }
    })
  },
  GetWebPorkNewDataFourChart: function (params) {
    return request({
      url: '/GetWebPorkNewDataFourChart',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000032,
        Data: params
      }
    })
  },
  GetWebPorkNewDataFourTable: function (params) {
    return request({
      url: '/GetWebPorkNewDataFourTable',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000033,
        Data: params
      }
    })
  },
  GetBeefCompanyTrend: function (params) {
    return request({
      url: '/GetBeefCompanyTrend',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000034,
        Data: params
      }
    })
  },
  GetBeefUserTrend2: function (params) {
    return request({
      url: '/GetBeefUserTrend2',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 2000000035,
        Data: params
      }
    })
  }
}
