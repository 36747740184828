import { dateFormat } from '@/utils'

export default {
  computed: {
    server () {
      return 'https://www.beeftochina.com.cn/StaticFiles/'
      // return 'http://192.168.10.52:5000/StaticFiles/'
    },
    isEN () {
      return this.$i18n.locale === 'en'
    },
    isLogin () {
      return this.$store.getters.token
    },
    today () {
      return dateFormat('YYYY-mm-dd', new Date())
    }
  },
  methods: {
    getUserProfile (type) {
      if (this.$i18n.locale === 'en') {
        // 英文版
        const userProfile = this.$store.getters.userProfile
        const visitModuleEn = userProfile.CanVisitModuleEn != null ? userProfile.CanVisitModuleEn.split('|') : []
        return userProfile.ChargeStataEn === 3 && visitModuleEn.includes(type) && (new Date(userProfile.ChargeEnExpireDate) > new Date())
      } else {
        // 中文版
        // eslint-disable-next-line no-debugger
        // debugger
        var userProfile = this.$store.getters.userProfile
        var visitModule = userProfile.CanVisitModule != null ? userProfile.CanVisitModule.split('|') : []
        return userProfile.ChargeState === 3 && visitModule.includes(type) && (new Date(userProfile.ChargeExpireDate) > new Date())
      }
    },
    // 获取附件地址
    getUrl (item) {
      return this.server + item.AppendixAddr
    },
    // 登录弹窗
    openLoginModal () {
      this.$bvModal.show('login-modal')
    },
    // 会员弹窗
    openMembershipModal () {
      this.$bvModal.show('MembershipsDialog')
    },
    // 登录弹窗
    openCnLoginModal () {
      this.$bvModal.show('cn-login-modal')
    },
    // 会员弹窗
    openCnMembershipModal () {
      this.$bvModal.show('CnMembershipsDialog')
    },
    // 截取时间
    getTime (time) {
      return time ? time.substring(0, 10) : ''
    },
    openNewWindow (path) {
      const routeData = this.$router.resolve({ path: path })
      const open = window.open(routeData.href, '_blank')
      if (open === null || typeof (open) === 'undefined') {
        this.$router.push(path)
      }
    },
    isDisabledMonth (year, month, targetMonth) {
      return year >= 2020 && month > targetMonth
    }
  }
}
