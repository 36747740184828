import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  GetEyes: function (params) {
    return request({
      url: '/GetEyes',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 5000000001,
        Data: params
      }
    })
  },
  AddDeleteWebEyes: function (params) {
    return request({
      url: '/AddDeleteWebEyes',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 5000000002,
        Data: params
      }
    })
  }
}
