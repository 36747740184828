import JsSHA from 'jssha'
import wx from 'weixin-js-sdk'

const APPID = 'wx41fb4a805edb0d1e'

/**
 * 初始化微信接口
 * @param {*} self
 * @param {*} code
 */
export function wxShareInit (self) {
  getSignature(self).then((res) => {
    wx.config({
      debug: false,
      appId: APPID,
      timestamp: res.timestamp,
      nonceStr: res.noncestr,
      signature: res.signature,
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ]
    })
  })
}

/**
 * 微信分享接口
 * @param {*} title 分享标题
 * @param {*} desc 分享描述
 * @param {*} link 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
 * @param {*} imgUrl 分享图标
 */
export function wxShareReady (title, desc, link, imgUrl) {
  wx.ready(function () {
    // 分享到微信好友、QQ好友
    wx.updateAppMessageShareData({
      title: title,
      desc: desc,
      link: link,
      imgUrl: imgUrl,
      success: function () {
        console.log('AppMessageShare is ready')
      }
    })
    // 分享到微信朋友圈、QQ空间
    wx.updateTimelineShareData({
      title: title,
      link: link,
      imgUrl: imgUrl,
      success: function () {
        console.log('TimelineShare is ready')
      }
    })
  })
}

// 生成签名
async function getSignature (self) {
  const res = await self.$store.dispatch('GetWxTicket')
  const ret = {
    noncestr: createNonceStr(),
    jsapi_ticket: res.ticket,
    timestamp: createTimestamp(),
    url: location.href.split('#')[0]
  }
  var string = raw(ret)
  const shaObj = new JsSHA('SHA-1', 'TEXT')
  shaObj.update(string)
  ret.signature = shaObj.getHash('HEX')
  return ret
}

/**
 * 生成随机字符串
 */
function createNonceStr () {
  return Math.random().toString(36).substr(2, 15)
}

/**
 * 获取时间戳
 */
function createTimestamp () {
  return parseInt(new Date().getTime() / 1000) + ''
}

/**
 * 对所有待签名参数按照字典序排序，并使用URL键值对的格式拼接成字符串
 * @param {*} args
 */
function raw (args) {
  var keys = Object.keys(args)
  keys = keys.sort()
  var newArgs = {}
  keys.forEach(function (key) {
    newArgs[key.toLowerCase()] = args[key]
  })

  var string = ''
  for (var k in newArgs) {
    string += '&' + k + '=' + newArgs[k]
  }
  string = string.substr(1)
  return string
}
