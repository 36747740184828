<template>
  <!-- v-share="getShareParam(news.NewsTitle, news.NewsSummary, shareAddress, news.NewsTitlePic)"  -->
  <div class="page-container">
    <div class="preheader">
      <div style="display: flex; align-items: center;">
        <img src="@/assets/image/app_icon.png" style="width: 120px; height: 120px;">
        <div style="margin-left: 20px;">
          <p style="font-size: 50px; font-weight: bold; margin: 0;">必孚</p>
          <p style="font-size: 35px; margin: 0;">进口牛肉行业第三方平台</p>
        </div>
      </div>
      <div class="open" @click="handleOpen($route.params.id)">打开</div>
    </div>
    <div style="position: relative;">
      <div class="header1">
        <div>
          <h1 style="font-size: 60px;">{{ news.NewsTitle }}</h1>
        </div>
        <div style="display: flex; align-items: center;">
          <img src="@/assets/image/faviconblue.png">
          <div style="margin-left: 20px;">
            <p style="font-size: 40px; color: #2ab4f3;">BTC必孚（中国）原创</p>
            <p style="font-size: 40px; color: #9e9e9e;">{{ news.NewsDateTime }}</p>
          </div>
        </div>
      </div>
      <div :class="{'hide-style': showMask}" class="content">
        <div v-html="news.NewsContext"/>
      </div>
      <div v-if="!showMask" class="footer">
        <div class="qrcode">
          <div style="width: 50%; display: flex; flex-direction: column; align-items: center;">
            <img src="@/assets/image/weixincode.jpg" style="width: 400px; height: 400px;">
            <p style="font-size: 30px; margin-top: 10px; color: #000000;">扫码关注必孚（中国）官方公众号</p>
          </div>
          <div style="width: 50%; display: flex; flex-direction: column; align-items: center;">
            <img src="@/assets/image/appqrcode.png" style="width: 400px; height: 400px;">
            <p style="font-size: 30px; margin-top: 10px; color: #000000;">扫码下载必孚App</p>
          </div>
        </div>
        <div class="statement">
          <p style="color: #000000; font-size: 30px; padding: 20px;">本站声明：凡本网注明“来源：BTC必孚（中国）”的作品，文章版权归必孚（中国）所有，商业或公司转载须经本网书面授权，转载必须注明“来源：BTC必孚（中国）”；凡本网注明“来源：XXX（非BTC必孚（中国））”的作品，均转载自其他媒体，目的在于传播更多信息，并不代表本网赞同其观点或对其真实性负责。</p>
        </div>
      </div>
      <div v-if="showMask" class="mask">
        <div style="height: 20%; background-image: linear-gradient(rgba(255,255,255,.5), #fff);"/>
        <div style="height: 80%; background: #fff; display: flex; justify-content: center;">
          <div style="margin-top: 100px; display: flex; flex-direction: column; align-items: center;">
            <img src="@/assets/image/pull_down_blue.png" @click="handleShowAll" style="width: 80px;" class="ani">
            <div class="appbtn" @click="handleOpenApp">App内打开</div>
          </div>
        </div>
      </div>
      <div v-if="!showMask" style="position: fixed; bottom: 50px; width: 100%; padding: 0 400px;" >
        <div class="appbtn" style="text-align: center;" @click="handleOpenApp">App内打开</div>
      </div>
    </div>
    <div v-if="list && list.length > 0" class="recom-box">
      <div style="height: 100px; display: flex; align-items: center; line-height: 50px;">
        <p style="border-left: 10px solid #0e82f7; padding-left: 10px; font-size: 50px; margin: 0; font-weight: bold;">必孚推荐</p>
      </div>
      <div v-for="(item, index) in list" :key="index" class="recom-item" @click="handleOpen(item.NewsID)">
        <div class="left">
          <p style="font-size: 50px;">{{ item.NewsTitle }}</p>
          <p style="font-size: 35px; color: #999999;"><span style="margin-right: 40px; color: #0e82f7;">打开App</span>{{ item.NewsDateTime }}</p>
        </div>
        <div class="right">
          <img :src="item.NewsTitlePic" style="height: 260px;">
        </div>
      </div>
    </div>
    <!--
    <tip-dialog id="shareTipModal" @cancel="handleCancel" @continue="handleOpen($route.params.id)"/>
    <b-modal id="modal-1" title="BootstrapVue" style="width:100% !important;">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
    -->
  </div>
</template>

<script>
// import $ from 'jquery'
import { markdownToHTML } from '@/utils'
// import TipDialog from './tip-dialog'
import { wxShareReady } from '@/utils/wxshare'

export default {
  components: {
    // TipDialog
  },
  data () {
    return {
      showMask: true,
      news: {},
      list: []
    }
  },
  computed: {
    shareAddress () {
      return 'https://www.beeftochina.com.cn/share/' + this.$route.params.id
    }
  },
  mounted () {
    this.getNews()
    this.getRecommenArticle()
  },
  methods: {
    getNews () {
      this.$store.dispatch('GetAppNewsDetail', {
        NewsID: this.$route.params.id,
        IsShare: true
      }).then(() => {
        this.news = this.$store.getters.newsRow
        this.news.NewsContext = markdownToHTML(this.news.NewsContext)
        document.title = this.news.NewsTitle
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.news.NewsTitle)
        document.querySelector('meta[property="og:image"]').setAttribute('content', this.news.NewsTitlePic)
        document.querySelector('meta[property="og:abstract"]').setAttribute('content', this.news.NewsSummary)
        document.querySelector('meta[property="og:url"]').setAttribute('content', this.shareAddress)
        wxShareReady(this.news.NewsTitle, this.news.NewsSummary, this.shareAddress, this.news.NewsTitlePic)
      })
    },
    handleOpen (id) {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.includes('iphone')) {
        window.location.href = `https://share.beeftochina.com.cn/btc?article=${id}`
      } else {
        if (ua.match(/MicroMessenger/i) && (ua.match(/MicroMessenger/i).toString() === 'micromessenger')) {
          window.location.href = `https://www.beeftochina.com.cn/sharedownload?id=${id}`
        } else {
          window.location.href = `btc://com.beeftochina.beefmeat?article=${id}`
          setTimeout(() => {
            if (!document.hidden) {
              window.location.href = `https://www.beeftochina.com.cn/sharedownload?id=${id}`
            }
          }, 1000)
        }
      }
    },
    handleOpenApp () {
      // console.log('----')
      // $('#shareTipModal').modal('show')
      // this.$bvModal.show('modal-1')
      const id = this.$route.params.id
      const ua = navigator.userAgent.toLowerCase()
      if (ua.includes('iphone')) {
        window.location.href = `https://share.beeftochina.com.cn/btc?article=${id}`
      } else {
        if (ua.match(/MicroMessenger/i) && (ua.match(/MicroMessenger/i).toString() === 'micromessenger')) {
          window.location.href = `https://www.beeftochina.com.cn/sharedownload?id=${id}`
        } else {
          window.location.href = `btc://com.beeftochina.beefmeat?article=${id}`
          setTimeout(() => {
            if (!document.hidden) {
              window.location.href = `https://www.beeftochina.com.cn/sharedownload?id=${id}`
            }
          }, 1000)
        }
      }
    },
    handleShowAll () {
      this.showMask = false
    },
    handleCancel () {
      this.showMask = false
    },
    getImgUrl (url) {
      return (url && !url.includes('http') ? `${window.location.protocol}//${window.location.host}${url}` : url) || ''
    },
    getShareParam (title, desc, link, imgUrl) {
      const params = {
        title: title,
        desc: desc,
        link: link,
        imgUrl: this.getImgUrl(imgUrl)
      }
      return params
    },
    getRecommenArticle () {
      this.$store.dispatch('GetSimilarArticle', {
        NewsID: this.$route.params.id
      }).then(() => {
        this.list = this.$store.getters.newsRows
      })
    }
  }
}
</script>

<style scoped>
.page-container {
  position: relative;
}
.preheader {
  height: 160px;
  background: #dde5fd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  position: sticky;
  z-index: 999;
  top: 0;
}
.header1 {
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 40px 20px 40px;
}
.header1 > div:last-child{
  display: flex;
}
.content {
  font-size: 100px;
  padding: 40px;
}
.footer {
  background: #ffffff;
}
.statement {
  background: #f5f5fe;
  margin: 20px 40px;
}
.open {
  font-size: 45px;
  height: 80px;
  border-radius: 40px!important;
  background: #0e82f7;
  padding: 0 40px;
  line-height: 80px;
  color: #ffffff;
}
.mask {
  position: absolute;
  width: 100%;
  height: 25%;
  bottom: 0;
}
.tips {
  width: 100%;
  height: 100%;
  background: url('https://btc-image-repo20190720.oss-cn-hangzhou.aliyuncs.com/static/tips.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
}
.hide-style {
  max-height: 95vh;
  overflow: hidden;
}
.appbtn {
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  color: #fff;
  background: #0e82f7;
  border-radius: 50px!important;
  padding: 0 60px;
  margin-top: 40px;
}
.recom-box {
  padding: 0 40px;
  margin: 40px 0;
}
.recom-item {
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
.recom-item .left {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ani {
  animation: arrow .4s infinite alternate;
  -webkit-animation: arrow .4s infinite alternate;
}

@keyframes arrow
{
  0% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}

@-webkit-keyframes arrow /* Safari 和 Chrome */
{
  0% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
.qrcode {
  display: flex;
  flex-direction: row;
}
</style>
